.table-container .ant-table-thead>tr>th {
    padding: 16px !important;
}

.table-container .ant-table-cell:first-child {
    text-align: center !important;
    padding: 0rem 1rem !important;
}

.table-container .ant-table-cell {
    padding: 0 !important;
    height: 4rem;
}

.table-container .table-cell-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 1rem;
    color: white;
}

.table-container .table-cell-content.bg-red {
    background-color: #ff6b6b !important;
}

.table-container .table-cell-content.bg-green {
    background-color: #68d391 !important;
}
